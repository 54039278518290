import { Controller } from "stimulus";
import { Socket } from "phoenix";

export default class extends Controller {
  static targets = ["qualificationData"]

  connect() {
    let params = new URLSearchParams(document.location.search);
    let siteDomain = document.domain;
    let opportunityId = $(this.qualificationDataTarget).data("opportunityId");
    let clientName = siteDomain.split(".")[0];
    let channelName = `rtq_${clientName}_${opportunityId}`;

    if (siteDomain.includes("localhost")) {
      var socketHost = "ws://" + siteDomain + "/socket";
    } else {
      var socketHost = "wss://" + siteDomain + "/socket";
    }

    let socket = new Socket(socketHost);
    socket.connect();
    let channel = socket.channel(channelName, { ff_rtq: params.get("ff_rtq") });
    channel.join();
  }
}
